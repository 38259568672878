import React from "react"
import Footer from "../../_App/Footer"
import Layout from "../../_App/layout"
import Navbar from "../../_App/Navbar"
import Seo from "../../_App/seo"
import PageBanner from "../PageBanner"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseDetail = ({
  seoTitle,
  pageTitle,
  cardItems,
  subTitle,
  para,
  blockquote,
  images,
}) => {
  return (
    <Layout>
      <Seo title={seoTitle} />

      <Navbar />
      <PageBanner pageTitle={pageTitle} />

      <div className="blog-details-area pb-80">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-md-8">
              <span className="sub-Heading">{subTitle}</span>
              <div className="blog-details-desc">
                <div className="article-content">
                  <p dangerouslySetInnerHTML={{__html: para}}></p>
                  <blockquote >
                    <p>{blockquote}</p>
                  </blockquote>

                  <div className="row block-gallery pb-4">
                    <div className="row" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      {images &&
                        images?.casedetailsJson?.images.map((item, index) => (
                          <div
                            className={
                              images.casedetailsJson.images.length > 1
                                ? "blocks-gallery-item col-md-4 col-sm-12"
                                : "blocks-gallery-item  col-sm-12"
                            }
                            key={index}
                          >
                            <GatsbyImage
                              image={getImage(item.image)}
                              alt={images.casedetailsJson.slug}
                            />
                          </div>
                        ))}
                    </div>
                    {cardItems.map(item => (
                      <div key={item.id}>
                        <h3>{item.heading}</h3>
                        <ul className="features-list">{item.listItems}</ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default CaseDetail
