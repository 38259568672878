import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Prime Trade Modernization "
const pageTitle = "Prime Trade Modernization"
const subTitle = "Modernization"
const para =
  "This trading application is a global web based solution that allows traders to route orders, via the internet or a direct data feed to the customer for execution. These orders are routed to the floor of open outcry exchanges, or the electronic exchanges. The current systems does not have automated CI/CD pipelines, runs on bare metal servers and limits ability to scale during peak or down periods."
const blockquote = `"Bridging the gaps between development and operations by enforcing automation."`

const cardItems = [
  {
    id: 1,
    heading: `Challenges`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Clean-up and optimize CI/CD pipeline.
        </li>
        <li>
          <Icon.Square /> Containerize the applications modules.
        </li>
        <li>
          <Icon.Square /> Migrate logging and monitoring to run in the OpenShift
          environment.
        </li>
        <li>
          <Icon.Square />
          Stand up the OpenShift environments.
        </li>
        <li>
          <Icon.Square />
          Optimize the infrastructure scaling so the application can meet peak
          demand and scale back down during slower periods.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Technical Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Migrate CI pipeline to Jenkins from legacy pipeline.
        </li>
        <li>
          <Icon.Square />
          Containerize the application modules in Docker.
        </li>
        <li>
          <Icon.Square />
          The system built is Cloud ready and deployed.
        </li>
        <li>
          <Icon.Square />
          Private Cloud Platform (RedHat OpenShift).
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Technical Implementation`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          DevOps and CI/CD principles for zero down time during deployments.
        </li>
        <li>
          <Icon.Square />
          Atyeti team transformed the current build and deployment process to
          align with Cloud deployment model.
        </li>
        <li>
          <Icon.Square />
          Employed Lift and Shift approach to move application from traditional
          VMs to RedHat OpenShift.
        </li>
        <li>
          <Icon.Square />
          Using Docker for containerization and Kubernetes for orchestration.
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <p>
          The Application will be containerized and run in a Private Cloud
          Platform that is Cloud ready and optimized to scale up or down based
          on demand. Support will have been greatly reduced due to CI/CD
          pipeline optimization.
        </p>
        <p>
          The customers time to market has been reduced by breaking the monolith
          application into microservices and greatly improving the ease of
          process visibility with the logging/monitoring/alerting Atyeti is
          implementing
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query FourthQuery {
    casedetailsJson(slug: { eq: "prime-trade-modernization" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
